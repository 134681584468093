.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 141px;
  padding: 19px 24px;

  .title {
    display: flex;
    align-items: center;

    .icon {
      width: 45px;
      height: 45px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &AllUsers {
        background-color: var(--color-pink1);
      }

      &OnlineUsers {
        background-color: var(--color-green1);
      }

      &PendingItems {
        background-color: var(--color-info1);
      }
    }

    h4 {
      margin-right: 16.5px !important ;
    }
  }

  .count {
    font-size: 32px;
    text-align: left;
    line-height: 19px;
    // padding-left: 30px !important;
  }
}
