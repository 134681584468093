.menu-item {
  position: relative;
  min-height: 100px !important;
  border-radius: 100px 0 0 100px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-active {
    background-color: var(--color-secondary1);

    $height: 50px;
    $width: 25px;
    $negative-width: -25px;

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: -1px;
      height: $height;
      width: $width;
      border-bottom-right-radius: $height;
      -moz-border-bottom-right-radius: $height;
      -webkit-border-bottom-right-radius: $height;
      -webkit-box-shadow: 0 $width 0 0 var(--color-secondary1);
      -moz-box-shadow: 0 $width 0 0 var(--color-secondary1);
      box-shadow: 0 $width 0 0 var(--color-secondary1);
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: -1px;
      height: $height;
      width: $width;
      border-top-right-radius: $height;
      -moz-border-top-right-radius: $height;
      -webkit-border-top-right-radius: $height;
      -webkit-box-shadow: 0 $negative-width 0 0 var(--color-secondary1);
      -moz-box-shadow: 0 $negative-width 0 0 var(--color-secondary1);
      box-shadow: 0 $negative-width 0 0 var(--color-secondary1);
    }
  }

  .icon-wrapper {
    margin-bottom: 3.5px;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .countBadge {
    position: absolute;
    right: 4px;
    bottom: calc(100% - 13px);
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
