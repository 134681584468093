@import '../mixins/media';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: var(--font-family-iran-sans);

  &::-webkit-scrollbar {
    display: none;
  }
}

a {
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  color: var(--color-dark1);
}

body {
  font-family: var(--font-vazir);
  background-color: var(--color-muted);
  direction: rtl;
  min-height: 100vh;
}

ul,
ol,
dl {
  list-style: none;
}

html,
body,
div,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}
