.searchBox {
  border-radius: 24px;
  background-color: var(--color-secondary1);
  padding: 5px 11px;
  height: 55px;
  display: flex;
  align-items: center;
  // grid-template-columns: 1fr 85px;
  // column-gap: 5px;

  & > button {
    width: 85px;
    margin-right: 5px;
    font-size: 17px;
  }

  & > input {
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-dark1);
    width: calc(100% - 85px - 5px);

    &:hover,
    &:active {
      border: none;
      outline: none;
    }
  }
}
