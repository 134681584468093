.wrapper {
  .select {
    padding: 0 10px;
    height: 100%;
    background-color: #fff !important;
  }
}

.seenUsers {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  & > * {
    background-color: var(--color-dark1);
    color: var(--color-white) !important;
    border-radius: 26px;
    padding: 2.5px 10px;
    margin-top: 8px !important;

    &:not(:first-child) {
      margin-left: 10px !important;
    }
  }
}
