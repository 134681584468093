.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary1);
  min-width: 52px;
  min-height: 52px;
  width: 52px;
  height: 52px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
