.online-status {
  min-width: 69px;
  height: 30px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;

  &-color {
    &-online {
      background-color: var(--color-green1);
    }

    &-offline {
      background-color: var(--color-gold1);
    }
  }
}
