.wrapper {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px !important ;

    h4 {
      display: flex;
      align-items: center;
    }
  }

  .date {
  }
}
