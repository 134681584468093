@mixin buttonSize($height, $fontSize, $fontWeight) {
  height: $height;
  font-size: $fontSize;
  font-weight: $fontWeight;
  white-space: nowrap;
}

@mixin buttonColor($backgroundColor, $color, $borderColor) {
  background-color: $backgroundColor;
  color: $color;
  border-color: $borderColor !important;

  &:hover {
    border-color: $borderColor;
  }
}

.button {
  // font-family: ;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 1px solid transparent;
  transition: 0.3s;

  &:hover {
    transform: scale(0.97);
  }

  &:focus,
  &:active {
    outline: none;
    border-color: transparent;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default !important;
  }

  &FullWidth {
    width: 100%;
  }

  &-size {
    &-large1 {
      @include buttonSize(145px, 16px, 400);
    }

    &-large2 {
      @include buttonSize(64px, 18px, 400);
    }

    &-medium1 {
      @include buttonSize(47px, 16px, 400);
    }

    &-medium2 {
      @include buttonSize(41px, 17px, 400);
    }

    &-medium3 {
      @include buttonSize(39px, 16px, 400);
    }

    &-small1 {
      @include buttonSize(34px, 14px, 400);
    }

    &-small2 {
      @include buttonSize(25px, 12px, 400);
    }
  }

  &-color {
    &-info1 {
      @include buttonColor(
        var(--color-info1),
        var(--color-white),
        var(--color-info1)
      );
    }

    &-info2 {
      @include buttonColor(
        var(--color-info2),
        var(--color-dark1),
        var(--color-info2)
      );
    }

    &-success1 {
      @include buttonColor(
        var(--color-green1),
        var(--color-white),
        var(--color-green1)
      );
    }

    &-secondary1 {
      @include buttonColor(
        var(--color-secondary1),
        var(--color-dark1),
        var(--color-secondary1)
      );
    }

    &-pink1 {
      @include buttonColor(
        var(--color-pink1),
        var(--color-dark1),
        var(--color-pink1)
      );
    }

    &-gold1 {
      @include buttonColor(
        var(--color-gold1),
        var(--color-dark1),
        var(--color-gold1)
      );
    }

    &-white {
      @include buttonColor(
        var(--color-white),
        var(--color-dark1),
        var(--color-white)
      );
    }

    &-dark1 {
      @include buttonColor(
        var(--color-dark1),
        var(--color-white),
        var(--color-dark1)
      );
    }
  }

  &-radius {
    &-low {
      border-radius: 8px;
    }

    &-medium {
      border-radius: 16px;
    }

    &-high {
      border-radius: var(--default-border-radius);
    }

    &-circle {
      border-radius: 50%;
    }
  }
}
