.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .searchBox {
    width: 60px;
    height: 60px;
    max-width: 100%;
    border-radius: 20px;
    background-color: var(--color-secondary1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 55px;
    transform: 0.3s;

    &:active {
      transform: scale(0.95);
    }
  }

  .buttons {
    margin-top: 32px;

    button {
      width: 60px;
      flex-direction: column;
      font-size: 16px;

      &:last-child {
        margin-top: 18px;
      }
    }
  }

  .features {
    margin-top: 65px;

    .item {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--color-secondary1);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: 0.3s;

      & > span {
        position: absolute;
        right: -4px;
        top: -13px;
      }

      &:active {
        transform: scale(0.9);
      }

      &:last-child {
        margin-top: 25px;
      }
    }
  }
}
