.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 26px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 58px;

  & > a {
    text-decoration: none;

    & > button {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 190px;
      width: 100%;
      height: 65px;

      & > div {
        width: 67px;
        border-radius: 40px;
        height: 100%;
        background-color: var(--color-white);
        max-height: 33.5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
