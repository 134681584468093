@import './../../styles/mixins/media';

.box {
  height: 70px;

  @include media('min', 'lg') {
    display: none;
  }
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 5;
  background-color: var(--color-white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  svg {
    cursor: pointer;
  }

  @include media('min', 'lg') {
    display: none;
  }
}
