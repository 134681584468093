.wrapper {
  padding: 5.25px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  display: inline;
  border-width: 2px;
  border-style: solid;

  * {
    white-space: nowrap;
  }
}

.variant {
  &Info {
    border-color: var(--color-info1);

    span {
      color: var(--color-info1) !important;
    }
  }

  &Pink {
    border-color: var(--color-pink1);

    span {
      color: var(--color-pink1) !important;
    }
  }

  &Success {
    border-color: var(--color-green1);

    span {
      color: var(--color-green1) !important;
    }
  }
}
