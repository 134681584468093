@import './../../styles/mixins/media';

@mixin typography($fontSize, $fontWeight) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-variant: normal;
  font-family: var(--font-family-iran-sans);
}

.typography {
  //   font-family: var(--font-vazir);
  margin: 0 !important;

  &-with-icon {
    display: flex !important;
    align-items: center !important;

    span {
      padding-left: 8px;
    }
  }

  &-variant {
    &-title {
      @include typography(20px, 400);

      @include media('max', 'md') {
        @include typography(20px, 400);
      }
    }

    &-subtitle1 {
      @include typography(17px, 400);

      @include media('max', 'md') {
        @include typography(17px, 400);
      }
    }

    &-subtitle2 {
      @include typography(16px, 400);

      @include media('max', 'md') {
        @include typography(16px, 400);
      }
    }

    &-body1 {
      @include typography(14px, 400);

      @include media('max', 'md') {
        @include typography(14px, 400);
      }
    }

    &-body2 {
      @include typography(14px, 400);

      @include media('max', 'md') {
        @include typography(13px, 400);
      }
    }

    &-text1 {
      @include typography(14px, 400);

      @include media('max', 'md') {
        @include typography(12px, 400);
      }
    }

    &-text2 {
      @include typography(12px, 400);

      @include media('max', 'md') {
        @include typography(12px, 400);
      }
    }
  }

  &-text-decoration {
    &-underline {
      text-decoration: underline !important;
    }

    &-none {
      text-decoration: none !important;
    }
  }

  &-align {
    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }
  }

  &-direction {
    &-rtl {
      direction: rtl;
    }

    &-ltr {
      direction: ltr;
      display: none;
    }
  }

  &-none-selection {
    cursor: default;

    &::selection {
      background-color: transparent;
    }
  }

  &-cursor-pointer {
    cursor: pointer;
  }
}
