@import './../../../../styles/mixins/media';

.wrapper {
  .generalFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 48px;

    @include media('max', 'md') {
      grid-template-columns: 1fr;
      row-gap: 10px;
    }
  }

  .selectGroup {
    margin-top: 33px;
  }

  .addCustomInput {
    margin-top: 36px;
  }

  .button {
    margin-top: 68px;

    @include media('max', 'md') {
      margin-top: 30px;
    }

    button {
      margin-right: auto;
      width: 300px;
      height: 50px;

      @include media('max', 'md') {
        width: 100%;
      }
    }
  }
}
