.wrapper {
  padding: 0 15px;
  overflow: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 15px;

    button {
      width: 100px;
    }
  }

  .textEditor {
  }
}
