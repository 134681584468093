.wrapper {
  .list {
    .item {
      padding-bottom: 3px;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .pagination {
    margin-top: 15px;
  }
}
