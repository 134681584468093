@import './../../styles/mixins/media';

.wrapper {
  background-color: var(--color-info1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
  padding-left: 5px;
  overflow-y: auto;
  max-height: 100vh;
  transition: 0.3s;
  height: 100%;

  @include media('max', 'md') {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    width: 130px;
    z-index: 9;
    overflow-y: auto;
  }

  &Show {
    @include media('max', 'md') {
      left: 0;
    }
  }
}

.shadow {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(42, 58, 101, 0.5);
  transition: 0.3s;
  visibility: collapse;
  opacity: 0;
  z-index: 8;

  &Show {
    visibility: initial;
    opacity: 1;

    @include media('min', 'lg') {
      display: none;
    }
  }
}
