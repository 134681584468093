@import './../../../../styles/mixins/media';

.wrapper {
  .generalFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 48px;

    @include media('max', 'md') {
      grid-template-columns: 1fr;
      row-gap: 10px;
    }
  }

  .furtherFields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 22.5px;
    margin-top: 40px;

    @include media('max', 'md') {
      grid-template-columns: 1fr;
      row-gap: 10px;
      margin-top: 10px;
    }
  }

  .shifts {
    margin-top: 32px;

    h3 {
      margin-bottom: 37px !important;

      @include media('max', 'md') {
        margin-bottom: 10px !important;
      }
    }
  }

  .button {
    margin-top: 68px;

    @include media('max', 'md') {
      margin-top: 30px;
    }

    button {
      margin-right: auto;
      width: 300px;
      height: 50px;

      @include media('max', 'md') {
        width: 100%;
      }
    }
  }
}
