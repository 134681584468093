@import './../../styles/mixins/media';

.label {
  padding-bottom: 20px !important;

  @include media('max', 'md') {
    padding-bottom: 5px !important;
  }
}

.wrapper {
  width: 56px;
  height: 28px;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  background-color: var(--color-secondary1);

  &::after {
    content: '';
    width: 18.5px;
    height: 18.5px;
    border-radius: 50%;
    background-color: var(--color-white);
    transition: 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% - 18.5px - 5px);
    background-color: var(--color-dark1);
  }

  &Active {
    background-color: var(--color-green1);

    &::after {
      right: 5px;
      background-color: var(--color-white);
    }
  }
}
