@import './../../styles/mixins/media';

.wrapper {
  background-color: var(--color-secondary1);
  overflow: hidden;

  @include media('min', 'lg') {
    display: flex;
    height: 100vh;
  }

  .sidebar {
    height: 100%;

    &Large {
      width: 350px;
    }

    &Small {
      width: 80px;
    }
  }

  .content {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    &Small {
      width: calc(100% - 350px - 90px);
    }

    &Large {
      width: calc(100% - 80px - 90px);
    }

    @include media('max', 'md') {
      width: 100%;
    }
  }

  .menuBar {
    width: 90px;

    & > * {
      height: 100%;
    }
  }
}
