@import './../../styles/mixins/media';

.wrapper {
  .label {
    padding-bottom: 20px !important;

    @include media('max', 'md') {
      padding-bottom: 5px !important;
    }
  }

  .text-field {
    border-radius: var(--default-border-radius);
    background-color: var(--color-secondary1);
    height: 48px;
    padding: 0 18px;
    width: 100%;
    text-align: center;
    border: 1px solid transparent;
    outline: none;
    transition: 0.3s;
    font-weight: 400;
    color: var(--color-dark1);
    display: inline;
    font-size: 14px;

    @include media('max', 'md') {
      font-size: 13px;
    }

    &::placeholder {
      color: var(--color-dark1);
    }

    &:focus {
      // border-color: var(--color-dark1);
      outline: none;
    }

    &:active {
      outline: none;
    }

    &:disabled {
      opacity: 0.8;
    }
  }

  .error {
    margin-top: 5px !important;
  }
}
