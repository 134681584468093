@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('./../../assets/fonts/iransans/iransans-bold.ttf') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('./../../assets/fonts/iransans/iransans-medium.ttf') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('./../../assets/fonts/iransans/iransans-light.ttf') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('./../../assets/fonts/iransans/iransans-ultra-light.ttf')
    format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('./../../assets/fonts/iransans/iransans.ttf') format('woff'); /* All Major Browsers */
}
