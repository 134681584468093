.uploader {
  display: flex;
  align-items: center;

  .circle {
    width: 99.5px;
    height: 99.5px;
    border-radius: 50%;
    background-color: var(--color-dark1);
    margin-left: 23px;
    cursor: pointer;
    position: relative;

    & > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    input {
      opacity: 0;
      cursor: pointer;
    }
  }
}
