@import './../../styles/mixins/media';

.wrapper {
  background-color: var(--color-dark1);
  border-radius: 12px;
  position: relative;
  // transition: 0.3s;

  .move {
    position: absolute;
    height: 4px;
    width: 60%;
    background-color: #ccc;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 26px;
    cursor: ns-resize;
  }

  textarea {
    font-weight: 400;
    color: var(--color-white);
    width: 100%;
    height: calc(100% - 26px - 34px - 8px - 8px);
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    padding: 8px 5px;
    font-size: 14px;

    @include media('max', 'md') {
      font-size: 13px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .fileButtons {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 5px;
    padding-right: 5px;

    &Item {
      width: 60px;
      height: 26px;
      background-color: transparent;
      color: var(--color-white);
      transition: 0.3s;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 7px;
      }
    }
  }

  .mainButton {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7.5px;

    & > div {
      display: flex;

      button {
        &:not(:last-child) {
          margin-left: 7px;
        }
      }
    }

    .blueButton {
      justify-content: space-between;

      span {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      width: 65px;
      height: 26px;
    }
  }
}
