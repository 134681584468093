@import './../../styles/mixins/media';

.wrapper {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary1);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-fixed {
    position: fixed;
  }

  &-absolute {
    position: absolute;

    // In mobile must be fixed :)
    @include media('max', 'md') {
      position: fixed;
    }
  }

  h3 {
    font-weight: bold;
    margin-top: 15px !important;
  }
}
