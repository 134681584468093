.wrapper {
  .fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8.5px;
    direction: ltr;
  }

  button {
    width: 45px;
    height: 23px;
    margin-top: 10px;
  }

  input {
    font-size: 14px;
    border-radius: var(--default-border-radius);
    background-color: var(--color-info2);
    color: var(--color-dark1);
    width: 100%;
    outline: none;
    border: none;
    text-align: center;
    height: 28px;
    padding: 0 8px;

    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
}
