@import './../../styles/mixins/media';

@mixin chooseBox($column) {
  & > div {
    & > div {
      font-weight: 400;
      font-size: 14px;
      display: grid;
      grid-template-columns: repeat($column, 1fr);
      white-space: nowrap;
      margin-right: 0;
      column-gap: 8px;

      & > * {
        margin-right: 0;
      }
    }
  }
}

.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    button {
      width: 100px;
    }
  }

  .content {
    padding: 0 15px;
    margin: 5px;

    .selectGroup,
    .selectUser {
      margin-top: 15px;

      h4 {
        margin-bottom: 18px !important;
      }

      @include chooseBox(2);
    }

    .selectType {
      margin-top: 25px;

      h4 {
        margin-bottom: 18px !important;
      }
    }

    .selectType {
      margin-top: 25px;

      &Header {
        margin-bottom: 18px;
      }
    }

    .selectSubject {
      margin-top: 25px;

      &Header {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > button {
          width: 45px;
          height: 23px;
          border-radius: 26px;
        }
      }
    }

    .selectStatus {
      margin-top: 25px;

      &Header {
        margin-bottom: 18px;
      }
    }

    .expireAt {
      margin-top: 25px;
    }

    .selectCustomers {
      margin-top: 25px;
      padding-bottom: 10px;

      &Header {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          display: flex;
          align-items: center;

          span {
            padding-left: 7px;
          }
        }
      }

      &Content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-weight: 400;
        font-size: 14px;
      }

      &Skeleton {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        margin-top: 8px;
        row-gap: 9px;

        & > * > * {
          height: 28px !important;
          border-radius: 26px;
        }
      }
    }
  }

  .textEditor {
    padding: 0 15px;
  }
}
