@import './../../styles/mixins/media';

.wrapper {
  padding: 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100px;
    }
  }

  .content {
    margin-top: 45px;

    & > * {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
