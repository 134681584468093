@import './../../../../styles/mixins/media';

.wrapper {
  .generalFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 48px;

    @include media('max', 'md') {
      grid-template-columns: 1fr !important;
      row-gap: 10px !important;
    }
  }

  .selectGroup {
    margin-top: 33px;
    margin-bottom: 33px;
  }

  .switches {
    margin-top: 60px;

    & > * {
      display: flex;
      align-items: center;

      p {
        margin-left: 15px !important;
        cursor: default;
      }
    }
  }

  .button {
    margin-top: 50px;

    @include media('max', 'md') {
      margin-top: 30px;
    }

    button {
      margin-right: auto;
      width: 300px;
      height: 50px;

      @include media('max', 'md') {
        width: 100%;
      }
    }
  }
}
