@import './../../styles/mixins/media';

.wrapper {
  border-radius: 24px;
  background-color: var(--color-secondary1);
  padding: 10px 14px;
  min-height: 95px;
  display: grid;
  grid-template-columns: 37px 1fr;
  column-gap: 10px;

  @include media('max', 'md') {
    width: 100%;
    overflow-x: auto;
    * {
      white-space: nowrap;
    }
  }

  table {
    border-collapse: collapse;
  }

  .select {
    padding: 0 10px;
    height: 100%;
  }

  .icon {
    width: 37px;
    height: 100%;
    background-color: var(--color-info2);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
