@import './../../styles/mixins/media';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media('max', 'md') {
    flex-direction: column;
    justify-content: center;
  }

  .rightBox {
    display: flex;
    align-items: center;

    button {
      &:not(:first-child) {
        margin-right: 11.25px;
      }
    }

    span {
      padding-right: 35px;
    }
  }

  .leftBox {
    display: flex;
    align-items: center;

    @include media('max', 'md') {
      margin-top: 15px;
    }

    span {
      padding-left: 14px;
    }
  }

  .button {
    width: 29px;
    height: 29px;
    background-color: var(--color-white);
    cursor: pointer;
  }

  .select {
    width: 85px;
    height: 29px;
    padding: 0 9px;
    background-color: var(--color-white);
  }
}
