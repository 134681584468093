@import './../../styles/mixins/media';

.wrapper {
  position: relative;

  .label {
    padding-bottom: 20px !important;

    @include media('max', 'md') {
      padding-bottom: 5px !important;
    }
  }

  .button {
    width: 100%;
    padding: 0 18px;
    height: 48px;
    border-radius: var(--default-border-radius);
    font-size: 14px;

    &:hover {
      transform: scale(1);
    }
  }

  .items {
    position: absolute;
    right: 0;
    z-index: 2;
    top: calc(100% + 5px);
    background-color: var(--color-white);
    border-radius: var(--default-border-radius);
    padding: 10px;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 10px;

    span {
      text-align: center;
    }
  }

  .error {
    margin-top: 5px !important;
  }
}
