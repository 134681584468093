@import './../../styles/mixins/media';

.wrapper {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    padding-top: 15px;

    button {
      width: 100px;
    }
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 15px;

    &Info {
      display: flex;
      align-items: center;

      &Text {
        margin-right: 4px;
      }
    }
  }

  .content {
    overflow: auto;

    .chats {
      padding: 5px 10px;
      padding-bottom: 10px;
      overflow: auto;

      @include media('max', 'md') {
        height: calc(100vh - 302px);
      }

      & > * {
        &:not(:first-child) {
          margin-top: 7px;
        }
      }
    }
  }

  .textEditor {
    padding: 0 15px;
  }
}
