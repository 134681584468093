@import './../../styles/mixins/media';

.wrapper {
  .item {
    display: flex;
    align-items: flex-start;

    @include media('max', 'md') {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--color-secondary2);

      &:first-child {
        border-top: 1px solid var(--color-secondary2);
        padding-top: 15px;
      }

      & > * {
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    .remove {
      margin-bottom: 10.5px;
      margin-left: 20px;

      @include media('max', 'md') {
        margin: 0;
      }
    }

    .fieldGroup {
      &:not(:last-child) {
        margin-left: 50px;
      }

      @include media('max', 'md') {
        margin-left: 0;
        width: 100%;
      }
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .add {
    display: flex;
    align-items: center;
    margin-top: 45px;

    @include media('max', 'md') {
      margin-top: 25px;
    }

    button {
      margin-left: 21px;
    }

    p {
      cursor: pointer;
    }
  }

  button {
    width: 55px;
    height: 28px;
  }
}
