@import './../../styles/mixins/media';

.wrapper {
  .card {
    border-radius: 26px;
    padding: 20px;
    background-color: var(--color-dark1);

    @include media('max', 'md') {
      padding: 10px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;

      @include media('max', 'md') {
        display: block;
      }

      .files {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: -7px;
        margin-top: -5px;

        & > * > button {
          margin-right: 7px;
          background-color: transparent;
          color: var(--color-white);
          width: 82.871px;
          height: 35px;
          font-size: 15px;
          margin-top: 5px;
        }
      }

      &General {
        display: flex;
        align-items: center;
        margin-left: -7px;

        @include media('max', 'md') {
          margin-top: 10px;
        }

        & > * {
          width: 82.871px;
          height: 35px;
          font-size: 15px;
          margin-left: 7px;
        }
      }
    }
  }

  .info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 22px;

    * {
      white-space: nowrap;
    }

    .line {
      position: relative;
      right: 25px;
      top: 1px;
    }

    .badgeTime {
      position: absolute;
      right: 220px;
      top: calc(50% - 17.5px);
      padding: 0 20px;
      border-radius: 26px;
      background-color: var(--color-secondary1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
    }

    .badgeTimeSuccess {
      background-color: var(--color-green1);
    }

    &Content {
      position: relative;
      top: 9px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 390px);

      & > * {
        &:not(:first-child) {
          margin-right: 15px !important;
        }
      }
    }
  }

  .badgeFinishedTime {
    display: flex;
    flex-direction: row-reverse;

    @include media('max', 'md') {
      margin-top: 5px;
    }

    & > * {
      padding: 0 25px;
      border-radius: 26px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-pink1);
      text-align: center;
    }
  }
}
