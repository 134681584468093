@import './../../../../styles/mixins/media';

.wrapper {
  margin-bottom: 26px;

  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;

    .row {
      width: 100% !important;
      height: 31px !important;

      @include media('max', 'md') {
        display: none;
      }
    }

    & > * {
      width: calc(20% - 10px);

      @include media('max', 'md') {
        width: 100%;
        margin-top: 12px;
      }
    }
  }
}
