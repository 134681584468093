@import './../../styles/mixins/media';

.wrapper {
  padding: 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 22px;
    cursor: pointer;

    svg {
      cursor: pointer;
      transition: 0.3s;
    }

    @include media('min', 'lg') {
      display: none;
    }
  }

  .content {
    padding: 22px;
    transform: 0.3s;

    @include media('max', 'md') {
      padding-top: 0;
      display: none !important;
    }

    &Show {
      display: block !important;
    }
  }
}
