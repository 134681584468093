@import './../../styles/mixins/media';

@mixin chooseBox($column) {
  & > div {
    & > div {
      font-weight: 400;
      font-size: 14px;
      display: grid;
      grid-template-columns: repeat($column, 1fr);
      white-space: nowrap;
      margin-right: 0;
      column-gap: 8px;

      & > * {
        margin-right: 0;
      }
    }
  }
}

.wrapper {
  padding: 0 15px;
  overflow: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 15px;

    button {
      width: 100px;
    }
  }

  .content {
    margin-bottom: 30px;

    .selectGroup {
      margin-top: 10px;

      h4 {
        margin-bottom: 18px !important;
      }

      @include chooseBox(2);
    }
  }
}
