@import './../../styles/mixins/media';

.wrapper {
  padding: 5px 13px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;

  .files {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -7px;
    margin-top: 5px;

    & > * > button {
      margin-right: 7px;
      background-color: var(--color-info1);
      color: var(--color-white);
      width: 82.871px;
      height: 35px;
      font-size: 15px;
      margin-top: 5px;
      width: 70px;
    }
  }

  &Me {
    border-color: transparent;
    background-color: var(--color-secondary1);
  }

  &User {
    border-color: var(--color-secondary1);
  }

  .footer {
    margin-top: 12px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
}
