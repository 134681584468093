@import './../../styles/mixins/media';

.wrapper {
  background-color: var(--color-white);
  transition: 0.3s;
  height: 100%;
  position: relative;

  &Small {
    padding: 20px 0;
  }

  &Sidebar {
    height: 100%;
    overflow: auto;
  }

  @include media('max', 'md') {
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: 9;
    overflow-y: auto;
  }

  &Show {
    @include media('max', 'md') {
      right: 0;
    }
  }
}

.menu {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--color-secondary1);
  position: absolute;
  left: -19px;
  top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 55;

  @include media('max', 'md') {
    display: none !important;
  }

  & > * {
    width: 5.5px;
    height: 5.5px;
    background-color: var(--color-dark1);
    border-radius: 50%;
  }

  &Large {
    flex-direction: column;
    left: -16px;

    & > * {
      &:not(:first-child) {
        margin-top: 3px;
      }
    }
  }

  &Small {
    flex-direction: row;

    & > * {
      &:not(:last-child) {
        margin-left: 3px;
      }
    }
  }
}

.shadow {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(42, 58, 101, 0.5);
  transition: 0.3s;
  visibility: collapse;
  opacity: 0;
  z-index: 8;

  &Show {
    visibility: initial;
    opacity: 1;

    @include media('min', 'lg') {
      display: none;
    }
  }
}
