@import './../../styles/mixins/media';

.card {
  border-radius: var(--default-border-radius);
  background-color: var(--color-white);
  padding: 22px;

  @include media('max', 'md') {
    padding: 15px 10px;
  }
}
