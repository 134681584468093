@import './../../styles/mixins/media';

.label {
  padding-bottom: 20px !important;

  @include media('max', 'md') {
    padding-bottom: 5px !important;
  }
}

.select {
  position: relative;

  .arrow {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }

  select {
    border-radius: var(--default-border-radius);
    background-color: var(--color-secondary1);
    height: 48px;
    padding: 0 18px;
    width: 100%;
    text-align: center;
    border: 1px solid transparent;
    outline: none;
    transition: 0.3s;
    font-weight: 400;
    color: var(--color-dark1);
    display: inline;
    font-size: 14px;
    background-image: none;
    cursor: pointer;
    appearance: none;
  }

  .placeholder {
    color: var(--color-dark1) !important;
  }
}
