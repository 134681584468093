@import './../../styles/mixins/media';

.box {
  max-width: 100%;
  overflow: auto;

  // @include media('max', 'md') {
  //   overflow-x: auto;
  //   overflow-y: hidden;
  // }

  .wrapper {
    border-collapse: collapse;
    width: 100%;

    @include media('max', 'md') {
      * {
        white-space: nowrap;
      }
    }

    thead {
      tr {
        th {
          padding-bottom: 15px;
          font-weight: 400;
          padding: 0 3px;

          @include media('max', 'md') {
            padding-left: 12px;
            padding-right: 12px;
          }

          &:first-child {
            padding-right: 0 22px;
          }

          &:last-child {
            padding-left: 0 22px;
          }
        }
      }
    }

    tbody {
      tr {
        border-top: 7px solid var(--color-secondary1);

        td {
          background-color: var(--color-white);
          padding: 17px 3px;
          text-align: center;
          color: var(--color-dark1);

          &:first-child {
            border-radius: 0 var(--default-border-radius)
              var(--default-border-radius) 0;
            padding-right: 22px;
          }

          &:last-child {
            border-radius: var(--default-border-radius) 0 0
              var(--default-border-radius);
            padding-left: 22px;
          }
        }
      }
    }
  }
}
