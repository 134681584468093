.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -18px;
  margin-top: -12px;

  .item {
    border-radius: 30px;
    height: 35px;
    padding: 0 20px;
    background-color: var(--color-secondary1);
    color: var(--color-dark1);
    cursor: pointer;
    margin-top: 12px;
    margin-right: 18px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    word-break: break-all;
    overflow-x: hidden;

    &:active {
      transform: scale(0.9);
    }

    &Active {
      background-color: var(--color-info2);
    }
  }
}
