.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  & > * {
    display: flex;
    align-items: center;
    max-width: 65%;
  }

  .user {
    margin-right: 8px;
  }

  .onlineStatus {
    margin-left: 8px;
    flex-wrap: nowrap;
  }

  .unseen {
    margin-left: 5px;
  }

  h5 {
    margin-bottom: 3px;
  }
}
