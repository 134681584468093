@import '../../styles/mixins/media';

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  visibility: collapse;
  opacity: 0;
  z-index: 60;

  &Opened {
    opacity: 1;
    visibility: initial;
  }

  .closeBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 58, 101, 0.5);
    z-index: 11;
  }

  .modal {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
    padding: 25px 27px;
    border-radius: 26px;
    width: 1140px;
    max-width: 95%;
    max-height: 95%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s;
    opacity: 0;
    z-index: 12;

    @include media('max', 'md') {
      padding: 25px 15px;
    }

    &Opened {
      top: 50%;
      opacity: 1;
    }

    &Header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 119px;
      }
    }

    &Body {
      margin-top: 45px;

      @include media('max', 'md') {
        margin-top: 15px;
      }
    }
  }
}
