.wrapper {
  padding: 15px 25px;
  border-radius: 30px;
  background-color: var(--color-secondary1);

  .shifts {
    ul {
      display: grid;
      align-items: center;
      column-gap: 19px;
      row-gap: 13px;

      li {
        background-color: transparent !important;
        padding: 0 !important;
      }
    }
  }

  .field {
    background-color: #fff !important;
  }
}
