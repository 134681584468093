:root {
  // Dark Colors
  --color-dark1: #2a3a65;

  // Secondary Colors
  --color-secondary1: #f0f6ff;
  --color-secondary2: #757575;

  // Info Colors
  --color-info1: #29b9fc;
  --color-info2: #82d6f7;

  // Pink Colors
  --color-pink1: #f283b4;

  // Danger Colors
  --color-danger1: #e74c3c;

  // Green Colors
  --color-green1: #00debd;

  // Gold Colors
  --color-gold1: #fabd85;

  // Other Colors
  --color-white: #ffffff;
  --color-black: #000000;

  // Fonts
  --font-family-iran-sans: IRANSans;

  // other
  --default-border-radius: 40px;
}
