@import './../../styles/mixins/media';

.wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .back {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--color-secondary1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;

    @include media('max', 'md') {
      display: none;
    }
  }

  .user {
    display: grid;
    grid-template-columns: 1fr 83px;
    align-items: center;
    margin-bottom: 15px;

    button {
      font-size: 17px !important;
    }

    & > * {
      &:first-child {
        display: flex;
        align-items: center;

        p {
          margin-right: 15px !important;
        }
      }
    }
  }

  .buttons {
    display: grid;
    column-gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;

    svg {
      margin-left: 10px;
    }

    button {
      font-size: 17px;
      height: 50px;

      @include media('max', 'md') {
        font-size: 17px;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;

    button {
      width: 130px;
      font-size: 15px !important;
      display: flex;
      align-items: center;
    }
  }

  .notifications {
    margin-bottom: 20px;
  }

  .chats {
    padding-bottom: 10px;
  }
}
